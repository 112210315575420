import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import _ from 'lodash';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";

import AppBar from "@/components/AppBar";
import { getUserDetail } from "@/services/actions/userActions";
import { useDispatch, useSelector } from '@/services/hooks';
import CurrentPlan from "@/components/common/CurrentPlan";
import { FormattedMessage } from "react-intl";
import { ApiService } from "@/services/api";
import { Item } from "@/services/models";
import ItemContent from "@/components/MyPage/ItemContent";

export const MyPage: React.FC = () => {
  const dispatch = useDispatch();
  const { user, detail } = useSelector((state) => state.user);
  const [items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    dispatch(getUserDetail());
  }, [dispatch])

  useEffect(() => {
    ApiService.fetchItems().then((data) => {
      setItems(data);
    })
  }, [])

  const normalItem = useMemo(() => {
    return _.find(items, (item) => {
      return !item.purchase_limit && !!item.price;
    })
  }, [items])

  const limitItem = useMemo(() => {
    return _.find(items, (item) => {
      return item.purchase_limit === 1 && item.price === '2.00';
    })
  }, [items])

  return (
    <AppBar title={<FormattedMessage id="mypage.title" />}>
      <div style={{ width: '100%', height: '100%', position: 'relative', background: 'white' }}>
        <List sx={{ padding: 0 }}>
          <CurrentPlan
            enableLink
          />

          <ItemContent
            item={limitItem || normalItem}
          />

          <ListItem disablePadding sx={{ backgroundColor: '#F5F5F5', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', paddingTop: '24px' }}>
            <ListItem>
              <ListItemText primary={<FormattedMessage id="mypage.user_info" />} />
            </ListItem>
          </ListItem>
          {user && (
            <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <ListItemButton>
                <ListItemText primary={user.email} secondary={<FormattedMessage id="mypage.mail" />} />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Link to="/users/password-reset" style={{ width: '100%', textDecoration: 'none' }}>
              <ListItemButton>
                <ListItemText primary="●●●●●●●●●●" secondary={<FormattedMessage id="mypage.password" />} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="arrow-right">
                    <ArrowRightIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding sx={{ backgroundColor: '#F5F5F5', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', paddingTop: '24px' }}>
            <ListItem>
              <ListItemText primary={<FormattedMessage id="mypage.other_info" />} />
            </ListItem>
          </ListItem>
          <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Link to="/users/plan" style={{ width: '100%', textDecoration: 'none' }}>
              <ListItemButton>
                <ListItemText
                  primary={<FormattedMessage id="mypage.current_plan" />} secondary={`${detail?.plan_name}` || 'none'}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="arrow-right">
                    <ArrowRightIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Link to="/users/payment-history" style={{ width: '100%', textDecoration: 'none' }}>
              <ListItemButton>
                <ListItemText primary={<FormattedMessage id="mypage.purchase_history" />} secondary={<FormattedMessage id="mypage.plan_and_point" />} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="arrow-right">
                    <ArrowRightIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItemButton>
            </Link>
          </ListItem>

          <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Link to="/law" style={{ width: '100%', textDecoration: 'none' }}>
              <ListItemButton>
                <ListItemText primary={<FormattedMessage id="mypage.law" />} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="arrow-right">
                    <ArrowRightIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
      </div>
    </AppBar>
  );
};

export default MyPage;
