import React, { useState, useEffect, ReactElement } from "react";
import { useNavigate, Link } from "react-router-dom";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  Button,
  FormHelperText,
} from "@mui/material";
import { color } from "@/services/themes";

import AppBar from "@/components/AppBar";
import { loginUser } from "@/services/actions/userActions";
import { openSnackbar } from "@/services/actions/commonActions";
import { useDispatch, useSelector } from '@/services/hooks';
import { FormattedMessage } from "react-intl";
import { initTutorialStep } from "@/services/tutorial";
import _ from "lodash";
import { trackingUrlParam } from "@/utils";

export default function UserLogin() {
  const [password, setPassword] = useState<string>("");
  // const [error, setError] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [serverError, setServerError] = useState<string | ReactElement>("");
  const [errors, setErrors] = useState<{ email: string | ReactElement; password: string | ReactElement }>({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const loading = useSelector((state) => state.user.loading);
  const navigateTo = useNavigate();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setErrors((prevErrors) => ({ ...prevErrors, email: validateEmail(value) }));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: validatePassword(value),
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email: string): string | ReactElement => {
    const emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      return <FormattedMessage id="common.invalid.email_format" />
    }
    return "";
  };

  const validatePassword = (password: string): string | ReactElement => {
    if (_.size(password) < 8) {
      return <FormattedMessage id="common.password.invalid_length" />
    }
    return "";
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const res = await dispatch(loginUser(email, password));
      if (res.success) {
        if (res.data?.is_free_plan) {
          initTutorialStep();
        }
        navigateTo("/?login=true"); // state: { login: true } みたいに変更する
      } else {
        setServerError(<FormattedMessage id="user.login.login_failed" />);
      }
    } catch (err) {
      setServerError(<FormattedMessage id="user.login.login_failed" />);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const recoverySuccess = urlParams.get("recovery") === "true";

    if (recoverySuccess) {
      dispatch(openSnackbar(<FormattedMessage id="user.login.password_change_done" />));
    }

    if (user) {
      navigateTo('/')
    }
  }, []);

  const isFormValid =
    !errors.email && !errors.password && email && password;

  return (
    <AppBar title={<FormattedMessage id="user.login.title" />}>
      <Stack spacing={2} marginY={3} marginX={2} paddingTop={2}>
        {loading ? (
          <Typography variant="body1">loading...</Typography>
        ) : user ? (
          <Typography variant="body1"><FormattedMessage id="user.login.already_login1" /> {user.email} <FormattedMessage id="user.login.already_login2" /></Typography>
        ) : (
          <Typography variant="body1"><FormattedMessage id="user.login.input_login_info" /></Typography>
        )}

        <FormControl variant="outlined" fullWidth>
          <TextField
            required
            label={<FormattedMessage id="user.login.login_id" />}
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            autoComplete="email"
            helperText={errors.email}
            error={!!errors.email}
          />
        </FormControl>

        <FormControl variant="outlined" fullWidth error={!!errors.password}>
          <InputLabel htmlFor="outlined-adornment-password">
            <FormattedMessage id="user.login.password" />
          </InputLabel>{" "}
          <OutlinedInput
            id="outlined-adornment-password"
            label={<FormattedMessage id="user.login.password_label" />}
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            autoComplete="password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {errors.password && <FormHelperText>{errors.password}</FormHelperText>}
        </FormControl>
        <Typography variant="body2">
          <Link to="/users/password-reset" style={{ color: "gray", textDecoration: "none" }}>
            <FormattedMessage id="user.login.forget_password1" /><span style={{ color: color.main }}><FormattedMessage id="user.login.forget_password2" /></span>
          </Link>
        </Typography>
        {serverError && <p style={{ color: "#d32f2f" }}>{serverError}</p>}

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!isFormValid}
        >
          <FormattedMessage id="user.login.login" />
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            const queryWithHash = trackingUrlParam()
            navigateTo(`/users/signup${queryWithHash}`);
          }}
        >
          <FormattedMessage id="user.login.signup" />
        </Button>
      </Stack>

    </AppBar>
  );
}
