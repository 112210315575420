import {
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";

import AppBar from "@/components/AppBar";
import { useNavigate } from "react-router-dom";
import { trackingUrlParam, setSession } from "@/utils";
import { FormattedMessage } from "react-intl";
import TagManager from "react-gtm-module";

export default function AgeConfirmIndex() {
  const navigateTo = useNavigate();
  return (
    <AppBar title="FACESWITCH" isPrimary={true}>
      <Stack
        direction="column"
        spacing={2}
        margin={0}
        paddingTop={3}
        paddingBottom={2}
        paddingX={2}
        sx={{ background: '#FFFFFF', minHeight: 'calc(100vh - 64px)' }}
      >
        <Stack direction="column" spacing={2} margin={0} paddingY={3} paddingX={2} gap={2} >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="h5"
              color="rgba(0, 0, 0, 0.87)"
            >
              <FormattedMessage id="age_confirm.title" />
            </Typography>
            <img
              src="/18markGR.png"
              alt="18mark"
              style={{
                width: '64px',
                height: '64px',
                display: 'block',
                margin: '8px auto',
              }}
            />
            <Typography
              variant="h6"
              color="rgba(0, 0, 0, 0.87)"
            >
              <FormattedMessage id="age_confirm.age_confirm" />
            </Typography>
          </Box>
          <Typography
            variant="body1"
            color="rgba(0, 0, 0, 0.87)"
            margin={0}
            textAlign={'center'}
          >
            <FormattedMessage id="age_confirm.message1" />
            <br />
            <FormattedMessage id="age_confirm.message2" />
          </Typography>
        </Stack>
        <Stack
          gap={2}
          margin={0}
          sx={{ alignItems: 'center' }}
        >
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={() => {
              setSession('age_confirm', 'true');
              TagManager.dataLayer({
                dataLayer: {
                  event: "click_age_confirm_yes",
                }
              });
              const queryWithHash = trackingUrlParam()
              navigateTo(`/demo${queryWithHash}`);
            }}
            sx={{ color: 'rgba(0, 0, 0, 0.87)) !important', width: "240px" }}
          >
            <FormattedMessage id="age_confirm.yes" />
          </Button>
          <Button
            size="large"
            color="primary"
            variant="outlined"
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "click_age_confirm_no",
                }
              });
              window.location.href = 'https://www.google.com/';
            }}
            sx={{ width: "240px" }}
          >
            <FormattedMessage id="age_confirm.no" />
          </Button>
        </Stack>
      </Stack>
    </AppBar >
  );
}
