import { Button, List, ListItem } from '@mui/material';

import AppBar from "@/components/AppBar";
import { useDispatch } from '@/services/hooks';
import { useEffect, useLayoutEffect, useState } from 'react';
import { getUserDetail } from '@/services/actions/userActions';
import CurrentPlan from '@/components/common/CurrentPlan';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { GentamaThanks } from '@/components/AdTag/gentama';
import { ApiService } from '@/services/api';
import { Purchase } from '@/services/models';
import { DEBUG_KEY } from '@/settings';

const UserPlanComplete = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [purchase, setPurchase] = useState<Purchase | null>(null)
  const [searchParams] = useSearchParams()
  const purchaseId = searchParams.get('id')
  const isDebug = searchParams.get('debug') === DEBUG_KEY // 直URLアクセスを許容するためのデバッグモード

  // 直接URLを入力してアクセスした場合、マイページにリダイレクトする
  const isDirectAccess = !document.referrer.includes('https://credit.inter-credit.link')
  useLayoutEffect(() => {
    if (isDebug) {
      // APIのレスポンス待ちを擬似再現するために1秒後にデータをセットする
      setTimeout(() => {
        setPurchase({
          id: 1,
          user_id: 1,
          key: '00000',
          amount_dollar: 1000,
          amount_yen: 1000,
          name: 'test',
          purchase_type: 'recurring',
          created_at: '2022-01-01',
        })
      }, 1000);
      return;
    }
    if (isDirectAccess) {
      navigateTo('/mypage');
      return;
    }
    const id = Number(purchaseId)
    if (!id) {
      return;
    }
    ApiService.fetchPurchase(id)
      .then((res) => setPurchase(res))
  }, []);

  useEffect(() => {
    dispatch(getUserDetail());
  }, [dispatch])

  if (!!purchaseId && !purchase) {
    return <AppBar title="読込中です..." />
  }

  return (
    <AppBar title={<FormattedMessage id="user.plan_complete.title" />}>
      {!!purchase && (
        <Helmet>
          {GentamaThanks({ purchase })}
        </Helmet>
      )}
      <div style={{ width: '100%', height: '100%', position: 'relative', background: 'white' }}>

        <List sx={{ padding: 0 }}>
          <CurrentPlan />

          <ListItem sx={{ paddingTop: '24px', paddingBottom: 0, }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: '100%',
                padding: '8px 22px',
              }}
              onClick={() => {
                navigateTo('/');
              }}
            >
              <FormattedMessage id="user.plan_complete.home" />
            </Button>
          </ListItem>
          <ListItem sx={{ paddingTop: '24px' }}>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                width: '100%',
                padding: '8px 22px',
              }}
              onClick={() => {
                navigateTo('/mypage');
              }}
            >
              <FormattedMessage id="user.plan_complete.mypage" />
            </Button>
          </ListItem>
        </List >

      </div >
    </AppBar>
  );
};

export default UserPlanComplete;