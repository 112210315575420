/**
 * キャラクターのタイムライン画面
 * マイキャラクターや、他のキャラクターのタイムライン画面
 */
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  Typography,
  Icon,
} from "@mui/material";
import {
  ArrowBack,
  Edit,
} from "@mui/icons-material";

import AppBar from "@/components/AppBar";
import SceneComp from "@/components/Scene";
import { ApiService } from "@/services/api"
import { Character, Scene } from "@/services/models"
import LockWhite from "@/assets/LockWhite.svg";
import { FormattedMessage } from "react-intl";
import { useSelector } from "@/services/hooks";

export default function App() {
  const navigateTo = useNavigate();
  const { polling } = useSelector(state => state.common.progress);
  const pollingRef = useRef(false);
  const { characterId } = useParams<{ characterId: string }>();
  const [character, setCharacter] = useState<Character | null>(null);
  const [scenes, setScenes] = useState<Scene[]>([]);
  const [isFetched, setIsFetched] = useState<boolean>(false);

  const fetchCharacterScenesSwitched = async () => {
    ApiService.getCharacterScenesSwitched(Number(characterId))
      .then((data) => {
        setScenes(data);
        setIsFetched(true);
      })
      .catch((error) => console.error("Error:", error));
  };

  const fetchData = () => {
    fetchCharacterScenesSwitched()
  }

  const onFavoriteChanged = (sceneId: number) => {
    setScenes(scenes.map(scene => {
      if (scene.id === sceneId) scene.is_favorited = !scene.is_favorited;

      return scene;
    }));
  }

  useEffect(() => {
    ApiService.getCharacter(Number(characterId))
      .then((data) => setCharacter(data))
      .catch((error) => console.error("Error:", error));
    fetchData();
  }, []);

  useEffect(() => {
    // 生成完了時
    if (pollingRef.current && !polling) {
      fetchData();
    }
    pollingRef.current = polling;
  }, [polling]);

  return (
    <AppBar title="" hidden >
      {character && (
        // header
        <Card sx={{ boxShadow: 'unset' }}>
          <div style={{ position: "absolute" }}>
            <Stack direction="row" margin="26px 13px" padding={0} alignItems="center" spacing={2}>
              <IconButton sx={{ padding: 0 }} onClick={() => { navigateTo(-1) }}>
                <ArrowBack sx={{ color: "#FFFFFF" }} />
              </IconButton>
              <Typography variant="h6" sx={{ color: "#FFFFFF", fontSize: '18px', lineHeight: 1 }}>
                {character.name}
              </Typography>
              <Icon sx={{ display: character.is_public ? 'none' : 'inline-block' }}>
                <img src={LockWhite} alt="lock" />
              </Icon>
            </Stack>
          </div>
          <CardMedia
            component="img"
            image={character.url}
            title={character.name}
            sx={{ height: 170 }}
          />
          <CardContent>
            <Stack direction="row" justifyContent="space-between">
              <Avatar
                sx={{
                  width: 96,
                  height: 96,
                  marginTop: '-60px',
                  border: "2px solid white",
                }}
                src={character.url}
              />
              {
                character.is_editable &&
                (<IconButton sx={{
                  width: 28,
                  height: 28,
                }}
                  onClick={() => {
                    navigateTo(`/characters/${character.id}/edit`)
                  }}
                >
                  <Edit />
                </IconButton>)
              }
            </Stack>
          </CardContent>
        </Card>
        // feeds
      )}

      <Grid container paddingLeft={2} paddingRight={2} spacing={3} marginTop={0}>
        {scenes.map((scene, index) => (
          <Grid item xs={12} sm={12} key={scene.id}>
            <SceneComp
              scene={scene}
              showDivider={index < scenes.length - 1}
              onFavoriteChanged={(id: number) => onFavoriteChanged(id)}
              showCharacter={false}
              sceneType="switched"
            />
          </Grid>
        ))}
      </Grid>

      {isFetched && scenes.length === 0 &&
        <Stack textAlign="center">
          <Typography
            variant="body2"
            sx={{ whiteSpace: "pre" }}
          >
            <FormattedMessage id="character.view.not_found" />
          </Typography>
        </Stack>
      }
    </AppBar>
  );
}
