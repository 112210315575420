import {
  Box, Button,
  Stack,
  Typography,
} from "@mui/material";

import { FormattedMessage } from "react-intl";
import { mainTheme } from "@/services/themes.ts";
import TagManager from "react-gtm-module";
import { useSelector } from "@/services/hooks.ts";
import { useNavigate } from "react-router-dom";
import { trackingUrlParam, getLocale } from "@/utils.ts";

export default function DemoIndex() {
  const user = useSelector((state) => state.user.user);
  const navigateTo = useNavigate();
  const locale = getLocale();


  return (
    <Stack direction="column" margin={3} marginTop={2} gap={0}>
      <Box textAlign="center">
        <img
          src={"/logo_l@2x.png"}
          srcSet={"/logo_l@2x.png 2x,/logo_l@3x.png 3x"}
          alt="FACESWITCH"
          style={{
            backgroundColor: 'none',
            width: '120px',
          }}
        />
      </Box>

      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
        <Typography
          variant="subtitle2"
          color={mainTheme.palette.primary.main}
          fontSize={20}
        >
          {locale === 'ja' ? <>
            <span style={{ color: mainTheme.palette.primary.main }}><FormattedMessage
              id="demo2.description.text3.a" /></span>
            <span style={{ color: "#000" }}><FormattedMessage id="demo2.description.text3.b" /></span>
            <span style={{ color: mainTheme.palette.primary.main }}><FormattedMessage
              id="demo2.description.text3.c" /></span>
            <span style={{ color: "#000" }}><FormattedMessage id="demo2.description.text3.d" /></span>
          </> : <>
            <span style={{ color: "#000" }}><FormattedMessage id="demo2.description.text3" /></span>
          </>
          }
        </Typography>
      </Box>
      <Box textAlign={"center"} marginTop={2}>
        <video
          style={{
            aspectRatio: "1 / 1",
            maxWidth: "min(342px, calc(100vw - 20px))",
            borderRadius: "16px",
            border: `solid 4px ${mainTheme.palette.primary.main}`,
          }}
          src="/demo_assets/demo_movie_1.mp4"
          autoPlay
          muted
          playsInline
          loop
        />
      </Box>
      <Typography variant="body2" marginTop={1}>
        <FormattedMessage id="demo2.description.text2" />
      </Typography>
      <Box marginTop={3} style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
        <Typography
          variant="subtitle2"
          color={mainTheme.palette.primary.main}
          fontSize={20}
          fontWeight={500}
        >
          {locale === 'ja' ? <>
            <span style={{ color: mainTheme.palette.primary.main }}><FormattedMessage
              id="demo2.description.text1.a" /></span>
            <span style={{ color: "#000" }}><FormattedMessage id="demo2.description.text1.b" /></span>
            <span style={{ color: mainTheme.palette.primary.main }}><FormattedMessage
              id="demo2.description.text1.c" /></span>
            <span style={{ color: "#000" }}><FormattedMessage id="demo2.description.text1.d" /></span>
          </> : <><span style={{ color: "#000" }}><FormattedMessage id="demo2.description.text1" /></span></>
          }

        </Typography>
      </Box>
      <Box textAlign={"center"} marginTop={2}>
        <video
          style={{
            maxHeight: "calc(100vh - 120px)",
            maxWidth: "min(342px, calc(100vw - 20px))",
            borderRadius: "16px",
            border: `solid 4px ${mainTheme.palette.primary.main}`,
          }}
          src="/demo_assets/demo_movie_2.mp4"
          autoPlay
          muted
          playsInline
          loop
        />
      </Box>
      <Typography variant="body2" marginTop={1}>
        <FormattedMessage id="demo2.description.text4" />
      </Typography>
      <Box marginTop={3} style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
        <Typography
          variant="subtitle2"
          color={mainTheme.palette.primary.main}
          fontSize={20}
          fontWeight={500}
        >
          {locale === 'ja' ? <>
            <span style={{ color: "#000" }}><FormattedMessage id="demo2.description.text5.a" /></span>
            <span style={{ color: mainTheme.palette.primary.main }}><FormattedMessage
              id="demo2.description.text5.b" /></span>
            <span style={{ color: "#000" }}><FormattedMessage id="demo2.description.text5.c" /></span>
            <span style={{ color: mainTheme.palette.primary.main }}><FormattedMessage
              id="demo2.description.text5.d" /></span>
          </> : <>
            <span style={{ color: "#000" }}><FormattedMessage id="demo2.description.text5" /></span>
          </>
          }

        </Typography>
      </Box>
      <Box textAlign="center" marginTop={2}>
        <img
          src={"/demo_assets/demo_step@2x.png"}
          srcSet={"/demo_assets/demo_step@2x.png 2x, /demo_assets/demo_step@3x.png 3x"}
          alt="FACESWITCH"
          style={{
            backgroundColor: 'none',
            width: '327px',
          }}
        />
      </Box>
      <Typography
        variant="subtitle2"
        fontSize={20}
        textAlign="center"
        marginTop={3}
      >
        <FormattedMessage id="demo2.description.text6" />
      </Typography>
      <Typography variant="body2" marginTop={2} textAlign="center">
        <FormattedMessage id="demo2.description.text7" />
      </Typography>
      <Box textAlign="center" marginTop={2}>
        <img
          src={"/demo_assets/demo_video_sample01@2x.png"}
          srcSet={"/demo_assets/demo_video_sample01@2x.png 2x, /demo_assets/demo_video_sample01@3x.png 3x, /demo_assets/demo_video_sample01@4x.png 4x"}
          alt="FACESWITCH"
          style={{
            backgroundColor: 'none',
            width: '327px',
          }}
        />
      </Box>
      <Box textAlign="center" marginTop={1}>
        <img
          src={"/demo_assets/demo_video_sample02@2x.png"}
          srcSet={"/demo_assets/demo_video_sample02@2x.png 2x, /demo_assets/demo_video_sample02@3x.png 3x, /demo_assets/demo_video_sample02@4x.png 4x"}
          alt="FACESWITCH"
          style={{
            backgroundColor: 'none',
            width: '327px',
          }}
        />
      </Box>
      <Box textAlign="center" marginTop={1}>
        <img
          src={"/demo_assets/demo_video_sample03@2x.png"}
          srcSet={"/demo_assets/demo_video_sample03@2x.png 2x, /demo_assets/demo_video_sample03@3x.png 3x, /demo_assets/demo_video_sample03@4x.png 4x"}
          alt="FACESWITCH"
          style={{
            backgroundColor: 'none',
            width: '327px',
          }}
        />
      </Box>
      <Box textAlign="center" marginTop={1}>
        <img
          src={"/demo_assets/demo_video_sample04@2x.png"}
          srcSet={"/demo_assets/demo_video_sample04@2x.png 2x, /demo_assets/demo_video_sample04@3x.png 3x, /demo_assets/demo_video_sample04@4x.png 4x"}
          alt="FACESWITCH"
          style={{
            backgroundColor: 'none',
            width: '327px',
          }}
        />
      </Box>
      <Box position="fixed" bottom={0} left={0} width="100%" sx={{
        background: "rgba(255, 255, 255, 0.96)",
        boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.20), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);'
      }}>
        <Stack direction="column" spacing={1} gap={0} padding={2} alignItems="center">
          <Box justifyContent="center" display="flex">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              sx={{ gap: 1 }}
              onClick={() => {
                if (user) {
                  navigateTo('/users/plan')
                } else {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "demo_show_register_dialog",
                    }
                  });
                  const queryWithHash = trackingUrlParam()
                  navigateTo(`/users/signup${queryWithHash}`);
                }
              }}
            >
              {user
                ? <FormattedMessage id="demo.upgrade" />
                : (<>
                  <Stack direction="row" alignItems="center">
                    ＼
                    <Box sx={{ fontSize: "11px", lineHeight: "14px", fontWeight: 500, whiteSpace: 'pre-wrap' }}>
                      <FormattedMessage id="demo.easy" />
                    </Box>
                    ／
                  </Stack>
                  <FormattedMessage id="demo.register" />
                </>)}
            </Button>
          </Box>
          <Box justifyContent={'center'} display="flex">
            <Button
              size="small"
              onClick={() => {
                if (user) {
                  navigateTo('/')
                } else {
                  const queryWithHash = trackingUrlParam()
                  navigateTo(`/users/login?${queryWithHash}`);
                }
              }}
            ><FormattedMessage id="demo.login" /></Button>
          </Box>

        </Stack>
      </Box>
    </Stack >
  );
}
