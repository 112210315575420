import { TypedUseSelectorHook, useDispatch as ud, useSelector as us } from 'react-redux'
import type { RootState, AppDispatch } from '@/services/store'
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch
export const useDispatch: DispatchFunc = ud
export const useSelector: TypedUseSelectorHook<RootState> = us

export const useUrlParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getUrlParam = (key: string) => searchParams.get(key);
  const setUrlParam = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams, { replace: true });
  };
  const unsetUrlParam = (key: string) => {
    searchParams.delete(key);
    setSearchParams(searchParams, { replace: true });
  };
  const hasUrlParam = (key: string) => {
    const keys = Array.from(searchParams.keys());
    return _.includes(keys, key);
  };
  return { getUrlParam, setUrlParam, unsetUrlParam, hasUrlParam };
}