import { Box, Button, List, ListItem, Stack, Typography } from '@mui/material';
import { useState } from "react";
import _ from 'lodash';
import AppBar from "@/components/AppBar";
import { useSelector } from '@/services/hooks';
import CurrentPlan from '@/components/common/CurrentPlan';
import { Plan } from '@/services/models';
import { ApiService } from "@/services/api";
import { loadUTC } from '@/utils';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "@/services/hooks";
import PlanDowngradeDialog from "@/components/Dialog/plan_downgrade";
import { openSnackbar } from '@/services/actions/commonActions';
import { getUserDetail, getUserInfo } from '@/services/actions/userActions';
import PlanFeatureDescription from '@/components/common/Plan Feature Description';


type propType = {
  plans: Plan[];
};

const UserPlan = (props: propType) => {
  const { plans } = props;
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { detail } = useSelector((state) => state.user);
  const [isOpenDowngradeDialog, setIsOpenDowngradeDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCencel = async (text: string) => {
    if (loading) {
      return;
    }

    setLoading(true);
    ApiService.purchase_resign({ text })
      .then((data) => {
        if (data.success) {
          dispatch(openSnackbar(<FormattedMessage id="current_plan.change_plan_success" />));
          dispatch(getUserDetail());
          dispatch(getUserInfo());
          navigateTo('/users/plan');
        } else {
          console.error("resign response is false.");
          dispatch(openSnackbar(<FormattedMessage id="current_plan.change_plan_failed" />));
        }
      })
      .catch((error) => {
        console.error("resign error: ", error);
        dispatch(openSnackbar(<FormattedMessage id="current_plan.change_plan_failed" />));
      });
    setLoading(false);
  }

  return (
    <AppBar title={<FormattedMessage id="user.plan.title" />}>
      <div style={{ width: '100%', height: '100%', position: 'relative', background: 'white' }}>
        <List sx={{ padding: 0 }}>
          <CurrentPlan />
          <ListItem
            sx={{
              display: 'flex',
              padding: '24px 16px 0',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '0',
              alignSelf: 'stretch',
            }}
          >
            <PlanFeatureDescription
              plans={plans}
            />

            <Stack
              width="100%"
              marginTop={3}
              marginBottom={3}
              display="flex"
              gap={1}
            >
              <Button
                size="large"
                variant="outlined"
                sx={{
                  position: 'relative',
                  width: '100%',
                  borderRadius: 'var(--borderRadius, 4px)',
                }}
                onClick={() => { setIsOpenDowngradeDialog(true); }}
              >
                <FormattedMessage id="current_plan.downgrade_plan" />
                {
                  !detail?.is_continue_paid && (
                    <Box
                      position="absolute"
                      sx={{
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        background: '#0000008F',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        color="white"
                        sx={{
                          lineHeight: '40px',
                        }}
                      >
                        <FormattedMessage id="user.plan.bronze.downgraded" />
                      </Typography>
                    </Box>
                  )
                }
              </Button>
              {
                !detail?.is_continue_paid && (
                  <>
                    <Typography variant="body2">
                      <FormattedMessage id="user.plan.bronze.downgrade.info" />
                    </Typography>
                    <Box
                      sx={{
                        background: '#00000014',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        sx={{
                          lineHeight: '36px',
                          textAlign: 'center',
                        }}
                      >
                        <FormattedMessage id="user.plan.bronze.limit_time" />：{loadUTC(detail?.paid_expired_at).format('YYYY年M月D日')}(あと{loadUTC(detail?.paid_expired_at).diff(loadUTC(''), 'day')}<FormattedMessage id="user.plan.bronze.day" />)
                      </Typography>
                    </Box>
                  </>
                )
              }
            </Stack>

          </ListItem>
        </List >
      </div >

      <PlanDowngradeDialog
        open={isOpenDowngradeDialog}
        onExecute={async (body: string) => {
          await handleCencel(body);
          setIsOpenDowngradeDialog(false);
        }}
        onCancel={() => {
          setIsOpenDowngradeDialog(false);
        }}
      />
    </AppBar>
  );
};

export default UserPlan;
