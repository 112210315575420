import { Box, ButtonBase, Typography } from "@mui/material";

interface Props {
  label?: string;
  onClick: () => void;
}

export default function SmallGenreButton(props: Props) {
  return (
    <ButtonBase
      onClick={props.onClick}
      sx={{
        borderRadius: '18px',
      }}
    >
      <Box
        width="fit-content"
        minWidth="fit-content"
        sx={{
          border: '1px solid #00000033',
          padding: '5px 8px 5px 8px',
          borderRadius: '18px',
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="body1"
            fontSize="11px"
            color="primary"
            sx={{
              lineHeight: '1',
              fontWeight: 400,
            }}
          >
            #
          </Typography>
          <Typography
            variant="body1"
            fontSize="11px"
            sx={{
              color: '#000000DE',
              lineHeight: '1',
              fontWeight: 400,
            }}
          >
            {props.label}
          </Typography>
        </Box>
      </Box>
    </ButtonBase>
  )
}