import { Button, Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { useSelector } from '@/services/hooks';
import PointItem from "@/components/PointItem";
import TagManager from "react-gtm-module";

type propType = {
  enableLink?: boolean
  disableShowPoint?: boolean
}

export default function CurrentPlan(props: propType) {
  const { enableLink, disableShowPoint } = props;
  const { detail, user } = useSelector((state) => state.user);

  const handleMovePlan = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "move_plan_from_mypage",
        user_id: user?.id,
      }
    });
  }
  return (
    <>
      <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <ListItem component="div">
          <ListItemText
            disableTypography
            primary={<Typography variant="body2" color="rgba(0, 0, 0, 0.60)"><FormattedMessage id="current_plan.current_plan" /></Typography>}
            secondary={<Typography variant="h6" color="rgba(0, 0, 0, 0.87)">{`${detail?.plan_name}`} <FormattedMessage id="current_plan.plan" /></Typography>}
          />
          {
            enableLink && (
              <Link to="/users/plan" onClick={handleMovePlan}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                >
                  {<FormattedMessage id="current_plan.change_plan" />}
                </Button>
              </Link>)
          }
        </ListItem>
      </ListItem>
      {!disableShowPoint && (
        <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <ListItem component="div" sx={{ display: "block" }}>
            <List sx={{ padding: 0 }}>
              <ListItem>
                <PointItem
                  label={<FormattedMessage id="current_plan.switch_point" />}
                  description={<FormattedMessage id="current_plan.switch_point_description" />}
                  {...detail?.swap}
                  disableLimit
                />
              </ListItem>
              <Divider sx={{ margin: '8px 0' }} />
              <ListItem>
                <PointItem
                  displayCurrent
                  label={<FormattedMessage id="current_plan.character" />}
                  description={<FormattedMessage id="current_plan.character_description" />}
                  {...detail?.character}
                />
                <PointItem
                  displayCurrent
                  label={<FormattedMessage id="current_plan.favorite" />}
                  description={<FormattedMessage id="current_plan.favorite_description" />}
                  {...detail?.favorite}
                />
              </ListItem>
            </List>
          </ListItem>
        </ListItem >
      )}

    </>
  )
}
