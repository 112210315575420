import { useMemo } from 'react';
import { TUTORIAL_STEP } from "@/services/tutorial";
import { Box, Button, ButtonBase, Stack, Typography } from "@mui/material";

import CheckIcon from '@mui/icons-material/Check';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GenreDialog from "@/components/Dialog/genre";
import { useEffect, useState } from "react";
import _ from "lodash";
import { Genre } from "@/services/models";
import { ApiService } from "@/services/api";
import { useSelector, useUrlParams } from "@/services/hooks";
import GenreButton from "@/components/common/GenreButton";
import { FormattedMessage } from 'react-intl';

export default function GenreSearch() {
  const { getUrlParam, setUrlParam, unsetUrlParam } = useUrlParams();

  const [genres, setGenres] = useState<Genre[]>([])
  const [isShowSort, setIsShowSort] = useState(false);
  const [isShowGenreModal, setIsShowGenreModal] = useState(false);

  const tutorialStep = useSelector((state) => state.common.tutorial_step);
  const isNotTutorial = !tutorialStep || tutorialStep === TUTORIAL_STEP.done;

  const sort = _.includes(['recommend', 'latest', 'popularity'], getUrlParam('sort')) ? getUrlParam('sort') : 'recommend';
  const filter = getUrlParam('filter');

  useEffect(() => {
    ApiService.fetchGenres().then((data) => {
      setGenres(data);
    }).catch(

      (error) => console.error("Error:", error));
  }, [])

  const titleText = useMemo(() => {
    // ここら辺の日本語化は後で。そもそもジャンル自体日本語なので。
    if (filter === '新作') {
      return '新作の動画一覧';
    }
    if (filter === '準新作') {
      return '準新作の動画一覧';
    }
    if (filter) {
      const genre = _.find(genres, { name: filter });
      if (!genre) {
        return '';
      }
      return `${genre?.name}の動画一覧`;
    }
    return '';
  }, [filter, genres]);

  const genre = useMemo(() => {
    if (filter) {
      return _.find(genres, { name: filter });
    }
  }, [filter, genres]);

  if (!isNotTutorial) {
    return null;
  }


  return (
    <Stack>
      {titleText && (
        <Stack
          paddingX={2}
          marginBottom={2}
        >
          <Typography variant="subtitle1">
            {titleText}
          </Typography>
        </Stack>
      )}
      {
        !!titleText && (
          <Box
            padding="0 8px"
            display="flex"
            overflow="scroll"
            gap={1}
            marginBottom={2}
          >
            {filter === '新作' &&
              <GenreButton
                label="新作"
                onClick={() => {
                  unsetUrlParam('filter');
                }}
              />
            }
            {filter === '準新作' &&
              <GenreButton
                label="準新作"
                onClick={() => {
                  unsetUrlParam('filter');
                }}
              />
            }
            {
              genre && (
                <GenreButton
                  key={genre?.id}
                  label={genre?.name}
                  onClick={() => {
                    unsetUrlParam('filter');
                  }}
                />
              )
            }
          </Box>
        )
      }
      <Stack
        padding={2}
        sx={{
          backgroundColor: '#F2F2F2',
        }}
      >
        <Box
          paddingX={2}
          justifyContent="space-between"
          alignItems="center"
          display="flex"
        >
          <Box
            position="relative"
          >
            <ButtonBase
              onClick={() => { setIsShowSort(before => !before) }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="cetter"
                gap={1}
              >
                <Typography>
                  <FormattedMessage id={`search.${sort}`} />
                </Typography>
                <KeyboardArrowDownIcon
                  sx={{ fontSize: '24px' }}
                />
              </Box>
            </ButtonBase>
            {isShowSort && (
              <Stack
                width="186px"
                sx={{
                  position: "absolute",
                  top: '34px',
                  left: '-10px',
                  zIndex: 999,
                  boxShadow: `0px 4px 18px 3px #0000001F, 
              0px 10px 14px 1px #00000024, 
              0px 6px 6px -3px #00000033;`,
                  backgroundColor: 'white',
                }}
              >
                <ButtonBase
                  onClick={() => {
                    setUrlParam('sort', 'recommend');
                    setIsShowSort(false);
                  }}
                >
                  <Box
                    width="100%"
                    padding="8px 16px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body1"
                      textAlign="left"
                      color={sort === 'recommend' ? 'primary' : undefined}
                    >
                      <FormattedMessage id="search.recommend" />
                    </Typography>
                    {sort === 'recommend' && <CheckIcon color="primary" />}
                  </Box>
                </ButtonBase>
                <ButtonBase
                  onClick={() => {
                    setUrlParam('sort', 'latest');
                    setIsShowSort(false);
                  }}
                >
                  <Box
                    width="100%"
                    padding="8px 16px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body1"
                      textAlign="left"
                      color={sort === 'latest' ? 'primary' : undefined}
                    >
                      <FormattedMessage id="search.latest" />
                    </Typography>
                    {sort === 'latest' && <CheckIcon color="primary" />}
                  </Box>
                </ButtonBase>

                <ButtonBase
                  onClick={() => {
                    setUrlParam('sort', 'popularity');
                    setIsShowSort(false);
                  }}
                >
                  <Box
                    width="100%"
                    padding="8px 16px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body1"
                      textAlign="left"
                      color={sort === 'popularity' ? 'primary' : undefined}
                    >
                      <FormattedMessage id="search.popularity" />
                    </Typography>
                    {sort === 'popularity' && <CheckIcon color="primary" />}
                  </Box>
                </ButtonBase>

              </Stack>
            )}
          </Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FilterAltIcon />}
            onClick={() => {
              setIsShowGenreModal(true)
            }}
          >
            絞り込み
          </Button>
        </Box>

        <GenreDialog
          open={isShowGenreModal}
          onClose={() => {
            setIsShowGenreModal(false)
          }}
          genre_list={genres}
        />
      </Stack>
    </Stack>
  )
}