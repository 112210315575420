import { isAdult } from '@/constants';
import { routes } from '@/routes';
import _ from 'lodash';
import { Genre, Character, Scene, User, GenerateResponse, CheckGenerateStatusResponse, Item, Plan, Purchase, CFStreamSignedItem, Notice, RegenerateResponse, VerifyResponse, Banner } from "@/services/models";
import Axios from "axios";
import { trackingUrlParam, getLocale, toSearchParams } from '@/utils';
import store from '@/services/store';
import { updateMaintenanceMode } from '@/services/actions/commonActions';
export const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

export const axios = Axios.create({
  baseURL: apiEndpoint,
  headers: {
    'a_faceswich': isAdult ? '1' : '0',
    'a_locale': getLocale(),
  }
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      const { pathname } = window.location;
      const isRedirect = !!_.find(routes, (route) => {
        return route.login_required && route.path === pathname;
      })
      if (isRedirect) {
        const queryWithHash = trackingUrlParam()
        window.location.href = `/users/login${queryWithHash}`;
      }
    } else if (error.response && error.response.status === 503) {
      store.dispatch(updateMaintenanceMode(true))
    }
    return Promise.reject(error);
  }
);


// services/ApiService.ts
export class ApiService {
  // @NOTE fetch と axios が混在しているが、axios に統一する
  // 共通headerの件とかもあるので、axiosにします。

  static async getCharacters(): Promise<Character[]> {
    const response = await axios.get('/api/v1/characters', {
      withCredentials: true,
    });
    return response.data;
  }

  static async getTutorialCharacters(): Promise<Character[]> {
    const response = await axios.get('/api/v1/tutorial/characters', {
      withCredentials: true,
    });
    return response.data;
  }

  static async getSceneCount(): Promise<number> {
    const response = await axios.get('/api/v1/scenes/count', {
      withCredentials: true,
    });
    return response.data;
  }

  static async getScenes(params: any): Promise<Scene[]> {
    const qs = toSearchParams(params);
    const response = await axios.get(`/api/v1/scenes?${qs}`, {
      withCredentials: true,
    });
    return response.data;
  }

  static async getTrialScenes(): Promise<Scene[]> {
    const response = await axios.get('/api/v1/scenes/trial', {
      withCredentials: true,
    });
    return response.data;
  }

  static async getTutorialScenes(): Promise<Scene[]> {
    const response = await axios.get('/api/v1/tutorial/scenes', {
      withCredentials: true,
    });
    return response.data;
  }

  static async getFavoriteScenes(params: any): Promise<Scene[]> {
    const qs = toSearchParams(params);
    const response = await axios.get(`/api/v1/favorite_scenes?${qs}`, {
      withCredentials: true,
    });
    console.log("getFavoriteScenes", response.data);
    return response.data;
  }

  static async getSwitchedScenes(params: any): Promise<Scene[]> {
    const qs = toSearchParams(params);
    const response = await axios.get(`/api/v1/scenes/switched?${qs}`, {
      withCredentials: true,
    });
    console.log("getSwitchedScenes", response.data);
    return response.data;
  }

  static async getTutorialSwitchedScene(characterId: number, sceneId: number): Promise<Scene | null> {
    const response = await axios.get(`/api/v1/tutorial/scenes/switched?characterId=${characterId}&sceneId=${sceneId}`, {
      withCredentials: true,
    });
    return response.data;
  }

  static async getCharacter(item_id: number): Promise<Character> {
    const response = await axios.get(`/api/v1/characters/${item_id}`, {
      withCredentials: true,
    });
    console.log("getCharacter", item_id, response.data);
    return response.data;
  }

  static async updateCharacter(character_id: number | undefined, name: string): Promise<Character> {
    const response = await axios.put(`/api/v1/characters/${character_id}`, {
      name,
    }, {
      withCredentials: true,
    });
    return response.data;
  }

  static async deleteCharacter(character_id: number | undefined): Promise<boolean> {
    const response = await axios.delete(`/api/v1/characters/${character_id}`, {
      withCredentials: true,
    });
    return response.data;
  }

  static async getCharacterScenesSwitched(item_id: number): Promise<Scene[]> {
    const response = await axios.get(`/api/v1/characters/${item_id}/scenes/switched`, {
      withCredentials: true,
    });
    return response.data;
  }

  static async generate(character_id: number, scene_id: number | null): Promise<GenerateResponse> {
    const response = await axios.post('/api/v1/generate', {
      character_id,
      scene_id,
    },
    {
      withCredentials: true,
    });
    return response.data;
  }

  static async regenerate(swapped_history_id: number): Promise<RegenerateResponse> {
    const response = await axios.post(`/api/v1/regenerate/${swapped_history_id}`,
    {},
    {
      withCredentials: true,
    });
    return response.data;
  }

  static async check_generate_status(): Promise<CheckGenerateStatusResponse> {
    console.log("static async check_generate_status start: ");

    const response = await axios.get('/api/v1/check_generate_status', {
      withCredentials: true,
    });
    console.log("static async check_generate_status data: ", response.data);
    return response.data;
  }


  // ------------------------
  // auth
  // ------------------------
  static async register(props: {
    email: string,
    password: string,
    age: string,
    referral_code: string,
    gentama_code: string,
    monetrack_code: string,
    terms_accepted: boolean,
    referrer: string,
  }): Promise<void> {
    const response = await axios.post('/api/v1/register', props);
    return response.data;
  }

  static async token(email: string, password: string): Promise<User> {
    const response = await axios.post(
      '/api/v1/token',
      {
        email: email,
        password: password,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  static async me(): Promise<User> {
    const response = await axios.get('/api/v1/users/me', {
      withCredentials: true,
    });
    return response.data;
  }

  static async logout(): Promise<void> {
    const response = await axios.post(
      '/logout',
      {},
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  static async reset( email: string,): Promise<void> {
    const response = await axios.post('/api/v1/reset', {
      email: email,
    });
    return response.data;
  }

  static async update( token: string, password: string,): Promise<void> {
    const response = await axios.post('/api/v1/update', {
      token: token,
      password: password,
    });
    return response.data;
  }

  static async verify( token: string,): Promise<VerifyResponse> {
    const response = await axios.post('/api/v1/verify', {
      token: token,
    }, {
      withCredentials: true,
    });
    return response.data;
  }

  static async getMyCharacters(): Promise<Character[]> {
    const response = await axios.get('/api/v1/characters/my', {
      withCredentials: true,
    });
    return response.data;
  }

  static async createCharacter(name: string, image: File): Promise<Character> {
    const formData = new FormData();
    formData.append("upload_file", image);
    formData.append("character_name", name);

    const response = await axios.post('/api/v1/characters',
      formData,
      {
        withCredentials: true,
      });
    return response.data;
  }

  static async sceneLike( sceneId: number,): Promise<void> {
    const response = await axios.post(`/api/v1/scenes/${sceneId}/like`, null, {
      withCredentials: true,
    });
    return response.data;
  }

  static async sceneUnlike( sceneId: number,): Promise<void> {
    const response = await axios.post(`/api/v1/scenes/${sceneId}/unlike`, null, {
      withCredentials: true,
    });
    return response.data;
  }

  static async fetchPlans(): Promise<Plan[]> {
    const response = await axios.get('/api/v1/plan',
    {
      withCredentials: true,
    });
    return response.data;
  }

  static async fetchNotice(noticeId: string): Promise<Notice> {
    const response = await axios.get(`/api/v1/notice/${noticeId}`,
    {
      withCredentials: true,
    });
    return response.data;
  }

  static async fetchCFStreamSignedItems(page: number = 0): Promise<CFStreamSignedItem[]> {
    const response = await axios.get(`/api/v1/original_videos?page=${page}`,
    {
      withCredentials: true,
    });
    return response.data;
  }

  static async fetchSampleScenes(page: number = 0): Promise<CFStreamSignedItem[]> {
    const response = await axios.get(`/api/v1/sample_scenes?page=${page}`,
    {
      withCredentials: true,
    });
    return response.data;
  }

  static async fetchItems(): Promise<Item[]> {
    const response = await axios.get('/api/v1/item',
    {
      withCredentials: true,
    });
    return response.data;
  }

  static async fetchPurchaseHistory(): Promise<Purchase[]> {
    const response = await axios.get('/api/v1/purchase/history',
    {
      withCredentials: true,
    });
    return response.data;
  }

  static async fetchPurchase(purchaseId: number): Promise<Purchase> {
    const response = await axios.get(`/api/v1/purchase/${purchaseId}`,
    {
      withCredentials: true,
    });
    return response.data;
  }

  static async purchase_resign(data: { text: string }): Promise<{ success: boolean }> {
    const response = await axios.post('/api/v1/purchase/resign',
    data,
    {
      withCredentials: true,
    });

    return response.data;
  }

  static async sendContact(data: { email: string, name: string, content: string }): Promise<{ success: boolean }> {
    const response = await axios.post('/api/v1/contact',
    data,
    {
      withCredentials: true,
    });

    return response.data;
  }

  static async fetchGenres(): Promise<Genre[]> {
    const response = await axios.get('/api/v1/genres',
    {
      withCredentials: true,
    });
    return response.data;
  }

  static async fetchBanners(): Promise<Banner[]> {
    const response = await axios.get('/api/v1/banners',
    {
      withCredentials: true,
    });
    return response.data;
  }
}

