import { ReactElement } from "react";
import _ from "lodash";
import { Notice } from "@/services/models";
import { getTutorialStepSession } from "@/services/tutorial";

interface CommonState {
  snackbar: { open: boolean, message: string | ReactElement | null };
  alert_modal: { open: boolean, message: string | ReactElement | null };
  registration_popup: boolean,
  plan_dialog: { open: boolean, use_type: string | null };
  character_swap_dialog: { open: boolean, video_uid: string | null, scene_id: number | null, point: number, is_tutorial: boolean, };
  referral_code: string;
  gentama_code: string;
  monetrack_code: string;
  referrer: string;
  progress: { polling: boolean, complete_toggle: boolean };
  notices: [] | [Notice];
  tutorial_step?: string | null;
  is_maintenance: boolean;
}

const initialState: CommonState = {
  snackbar: { open: false, message: null },
  alert_modal: { open: false, message: null },
  registration_popup: false,
  plan_dialog: { open: false, use_type: 'view' },
  character_swap_dialog: { open: false, video_uid: null, scene_id: null, point: 0, is_tutorial: false, },
  referral_code: '',
  gentama_code: '',
  monetrack_code: '',
  referrer: '',
  progress: { polling: false, complete_toggle: false },
  notices: [],
  tutorial_step: getTutorialStepSession(),
  is_maintenance: false,
};

export const commonReducer = (state = initialState, action: any): CommonState => {
  switch (action.type) {
    case "OPEN_SNACKBAR":
      return { ...state, snackbar: { open: true, message: action.message } };
    case "CLOSE_SNACKBAR":
      return { ...state, snackbar: { open: false, message: null } };
    case "OPEN_ALERT_MODAL":
      return { ...state, alert_modal: { open: true, message: action.message } };
    case "CLOSE_ALERT_MODAL":
      return { ...state, alert_modal: { open: false, message: null } };
    case "OPEN_REGISTRATION_POPUP":
      return { ...state, registration_popup: true };
    case "CLOSE_REGISTRATION_POPUP":
      return { ...state, registration_popup: false };
    case "OPEN_PLAN_DIALOG":
      return { ...state, plan_dialog: { open: true, use_type: action.use_type } };
    case "CLOSE_PLAN_DIALOG":
      return { ...state, plan_dialog: { ...state.plan_dialog, open: false } };
    case "OPEN_CHARACTER_SWAP_DIALOG":
      return { ...state, character_swap_dialog: { open: true, video_uid: null, scene_id: action.scene_id, point: action.point, is_tutorial: action.is_tutorial, } };
    case "OPEN_CHARACTER_SWAP_DIALOG_WITH_SAMPLE":
      return { ...state, character_swap_dialog: { open: true, video_uid: action.video_uid, scene_id: null, point: 0, is_tutorial: false, } };
    case "CLOSE_CHARACTER_SWAP_DIALOG":
      return { ...state, character_swap_dialog: { open: false, video_uid: null, scene_id: null, point: 0, is_tutorial: false, } };
    case "SET_REFERRAL_CODE":
      return { ...state, referral_code: action.referral_code };
    case "SET_GENTAMA_CODE":
      return { ...state, gentama_code: action.gentama_code };
    case "SET_MONETRACK_CODE":
      return { ...state, monetrack_code: action.monetrack_code };
    case "SET_REFERRER":
      return { ...state, referrer: action.referrer };
    case "SET_PROGRESS_POLLING":
        return { ...state, progress: { ...state.progress, polling: action.value } };
    case "TOGGLE_PROGRESS_COMPLETE":
        return { ...state, progress: { ...state.progress, complete_toggle: !state.progress.complete_toggle  } };
    case "FETCH_NOTICE_SUCCESS":
      return  { ...state, notices: action.payload };
    case "READ_NOTICE_SUCCESS": {
      const d = _.find(state.notices, { id: Number(action.noticeId)});
      if (d) {
        d.is_read = true;
      }
      return  { ...state, notices: [...state.notices] };
    }
    case "USER_INFO_FAIL":
    case "USER_LOGOUT":
    case "USER_LOGIN_FAIL":
        return { ...state, notices: [] };
    case "SET_TUTORIAL_STEP":
        return { ...state, tutorial_step: action.step };
    case "UPDATE_MAINTENANCE_SUCCESS":
        return { ...state, is_maintenance: action.value };
    default:
      return state;
  }
};
