import { Item } from "@/services/models"
import { Box, Button, Stack, Typography } from "@mui/material"
import { FormattedMessage } from "react-intl"
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import { color } from "@/services/themes";
import { clickPayment, getLocale } from "@/utils";
import { useSelector } from "@/services/hooks";
import { useLocation } from "react-router-dom";

type PropTypes = {
  item?: Item | null
}

const ItemContent = (props: PropTypes) => {
  const { item } = props
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const locale = getLocale()

  if (!item) {
    return null
  }

  const handleItemChange = () => {
    if (user?.id) {
      clickPayment({
        hash_id: user.hash_id,
        email: user.email,
        price: item.price,
        item_id: item.id,
        back_path: location.pathname,
      });
    }
  };

  const minute = Math.round(item.max_swap / 80);

  return (
    <Stack
      display="flex"
      gap={2}
      padding={2}
    >
      <Typography variant="subtitle1">
        <FormattedMessage id="user.plan.item.can_buy_point" />
        {
          !!item.purchase_limit && (
            <>
              （
              <FormattedMessage id="user.plan.item.limit" />
              {item.purchase_limit}
              <FormattedMessage id="user.plan.item.count" />
              ）
            </>
          )
        }
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={1}
        padding={2}
        sx={{
          border: 'solid 2px',
          borderColor: color.tertiary,
          borderRadius: '2px',
        }}
      >
        <ControlPointDuplicateIcon />
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          width="100%"
        >
          <Typography
            fontFamily="Roboto"
            fontSize="24px"
            fontWeight={600}
          >
            {item.max_swap}
          </Typography>
          <Typography>
            <FormattedMessage id="user.plan.swap.unit" />
            {
              !item.purchase_limit ? null : locale === 'ja' ? (
                <>（{minute}<FormattedMessage id="user.plan.swap.unit_after_non_num" />）</>
              ) : <>（of {minute} min）</>
            }
          </Typography>
        </Box>
        <Typography
          fontFamily="Roboto"
          fontSize="24px"
          fontWeight={700}
          color='#0000000'
        >
          ${item.price}
        </Typography>
      </Box>

      <Stack
        spacing={2}
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <Typography variant="caption" sx={{ fontSize: '10px', color: 'rgba(0, 0, 0, 0.6)' }}>
          <FormattedMessage id="user.plan.free.button_caption" />
        </Typography>
        <Button
          variant="contained"
          color="tertiary"
          fullWidth
          sx={{
            marginTop: '8px !important',
          }}
          onClick={handleItemChange}
        >

          <>
            <FormattedMessage id="user.plan.buy_item.button" values={{ point: item.max_swap }} />
            {
              !!item.purchase_limit && (
                <>
                  （
                  <FormattedMessage id="user.plan.item.limit" />
                  {item.purchase_limit}
                  <FormattedMessage id="user.plan.item.count" />
                  ）
                </>
              )
            }
          </>
          <> ${item.price}</>
        </Button>
      </Stack>
    </Stack >
  );
}

export default ItemContent