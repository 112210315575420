import SceneComp from "@/components/Scene";
import { setTutorialStep } from "@/services/actions/commonActions";
import { ApiService } from "@/services/api";
import { useDispatch, useSelector } from "@/services/hooks";
import { Scene } from "@/services/models";
import { TUTORIAL_STEP, getTutorialSelect } from "@/services/tutorial";
import { Box, Button, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { FormattedMessage } from "react-intl";

const LIMIT = 10;
export default function Switched() {
  const dispatch = useDispatch();
  const completeToggle = useSelector((state) => state.common.progress.complete_toggle);
  const tutorialStep = useSelector((state) => state.common.tutorial_step);
  const isSwitchTutorial = tutorialStep === TUTORIAL_STEP.switch;
  const isViewTutorial = tutorialStep === TUTORIAL_STEP.view;
  const isNotTutorial = !tutorialStep || tutorialStep === TUTORIAL_STEP.done;
  const { user } = useSelector((state) => state.user);


  const [page, setPage] = useState(1);
  const [scenes, setScenes] = useState<Scene[]>([]);
  const [tutorialScene, setTutorialScene] = useState<Scene | null>(null);
  const [isLastScene, setIsLastScene] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const init = () => {
    setPage(1);
    setIsLastScene(false);
    setScenes([]);
    fetchScenes();
  }

  const fetchScenes = () => {
    // myscene で tutorialか無料ユーザー
    if (isViewTutorial || user?.is_free_plan) {
      const selected = getTutorialSelect();
      setScenes([]);
      ApiService.getTutorialSwitchedScene(selected.characterId, selected.sceneId).then((data) => {
        if (!isNotTutorial) {
          dispatch(setTutorialStep(TUTORIAL_STEP.character));
        }
        setTutorialScene(data);
      }).catch(() => { });
      if (!isNotTutorial) {
        return
      }
    }
    if (isSwitchTutorial) {
      setScenes([]);
      return;
    }

    const option = { page: 0 };

    ApiService.getSwitchedScenes(option)
      .then((data) => {
        if (data.length < LIMIT) {
          setIsLastScene(true);
        }
        setScenes(data);
      })
      .catch(() => setScenes([]));
  }

  const fetchNext = useCallback(() => {
    setLoadingMore(true);
    const option = { page };

    ApiService.getSwitchedScenes(option)
      .then((data) => {
        let isLastPage = false;
        if (data.length < LIMIT) {
          setIsLastScene(true);
          isLastPage = true;
        }
        TagManager.dataLayer({
          dataLayer: {
            event: "click_load_more",
            user_id: user?.id,
            page: page,
            is_last_page: isLastPage,
          }
        });
        setPage((before) => {
          return before + 1;
        });
        setScenes([...scenes, ...data]);
        setLoadingMore(false);
      });
  }, [scenes, page]);

  const onFavoriteChanged = (sceneId: number) => {
    setScenes(scenes.map(scene => {
      if (scene.id === sceneId) scene.is_favorited = !scene.is_favorited;
      return scene;
    }));
  }

  useEffect(() => {
    init()
  }, [tutorialStep, user, completeToggle])

  // TODO スイッチ完了時にリロード

  return (
    <>
      <Stack direction="column" spacing={0} marginTop={0} paddingLeft={2} paddingRight={2} gap={3}>
        {scenes.map((scene, index) => !scene.is_switch_trial && (
          <Box key={`${scene.id}_${index}`}>
            <SceneComp
              isTutorial={isSwitchTutorial}
              index={index}
              scene={scene}
              showCharacter
              showDivider={index < scenes.length - 1}
              onFavoriteChanged={(id: number) => onFavoriteChanged(id)}
              sceneType="switched"
            />
          </Box>
        ))}

        {scenes.length > 0 && !isLastScene && (
          <Stack
            marginTop={2}
            width="100%"
          >
            <Button
              onClick={fetchNext}
              variant="contained"
              fullWidth
              disabled={loadingMore}
            >
              <FormattedMessage id="home.load_more" />
            </Button>
          </Stack>
        )}

        {tutorialScene && (
          <Box>
            <SceneComp
              isTutorial={isSwitchTutorial}
              index={0}
              scene={tutorialScene}
              showCharacter
              showDivider={false}
              onFavoriteChanged={(id: number) => onFavoriteChanged(id)}
              sceneType="switched"
            />
          </Box>
        )}
      </Stack>

      {
        (!scenes.length && !tutorialScene) && (
          <Box sx={{ width: '100%', textAlign: 'center' }} color="text.secondary">
            <FormattedMessage id="home.empty_switched" />
          </Box>
        )
      }
    </>
  );
}