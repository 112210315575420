
import { ApiService } from "@/services/api";
import { useSelector } from "@/services/hooks";
import { Item, Banner } from "@/services/models";
import { TUTORIAL_STEP } from "@/services/tutorial";
import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";

export default function BannerComponent() {
  const navigateTo = useNavigate();
  const ref = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(0);
  const { user, detail } = useSelector((state) => state.user);
  const [items, setItems] = useState<Item[]>([]);
  const [banners, setBanners] = useState<Banner[]>([]);
  const tutorialStep = useSelector((state) => state.common.tutorial_step);
  const isNotTutorial = !tutorialStep || tutorialStep === TUTORIAL_STEP.done;
  const [isItemsLoaded, setIsItemssLoaded] = useState(false);

  const bannerList = useMemo(() => {
    if (_.isEmpty(user) || _.isEmpty(detail) || !isItemsLoaded || _.isEmpty(banners)) {
      return [];
    }
    const result = [];

    const alwaysBanner = _.filter(banners, (banner) => {
      return banner.display_type === 'always';
    })
    result.push(...alwaysBanner);

    if (isNotTutorial && detail?.switch_trial_completed && _.some(items, (item) => {
      return item.purchase_limit === 1 && item.price === '2.00';
    })) {
      const targetBanner = _.filter(banners, (banner) => {
        return banner.display_type === 'one_time_item';
      })
      result.push(...targetBanner)
    } else if (isNotTutorial && user?.is_free_plan && detail?.switch_trial_completed) {
      const targetBanner = _.filter(banners, (banner) => {
        return banner.display_type === 'free_plan';
      })
      result.push(...targetBanner)
    } else if (user && !user.is_free_plan) {
      const targetBanner = _.filter(banners, (banner) => {
        return banner.display_type === 'paid_plan';
      })
      result.push(...targetBanner)
    }

    return _.shuffle(result);
  }, [items, isNotTutorial, detail, user, banners])

  useEffect(() => {
    ApiService.fetchBanners().then((data) => {
      setBanners(data);
    })

    ApiService.fetchItems().then((data) => {
      setItems(data);
      setIsItemssLoaded(true);
    })
  }, [user])

  const updateWidth = () => {
    if (ref.current) {
      const w = Math.round(ref.current.offsetWidth * 0.7);
      setWidth(Math.max(w, 276));
    } else {
      setWidth(0);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    }
  }, []);

  const renderBanner = (banner: Banner) => {
    const ImgComp = (<img
      style={{ width: '100%', borderRadius: '8px' }}
      src={banner.public_path}
      alt={banner.name}
    />)

    if (!banner.link_url) {
      return (
        <Stack>
          {ImgComp}
        </Stack>
      )
    } else if (banner.link_url.indexOf('/') === 0) {
      return (
        <Stack>
          <Stack
            onClick={() => {
              navigateTo(banner.link_url as string)
              TagManager.dataLayer({
                dataLayer: {
                  event: "banner_click",
                  banner_id: banner.id,
                  user_id: user?.id,
                }
              });
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            {ImgComp}
          </Stack>
        </Stack>
      )
    }
    return (
      <Stack>
        <a
          href={banner.link_url}
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: "banner_click",
                banner_id: banner.id,
                user_id: user?.id,
              }
            });
          }}
        >
          {ImgComp}
        </a>
      </Stack>
    )
  }

  return (
    <Stack mt="12px" paddingX={2}>
      <Box
        sx={{
          width: '100%',
        }}
        ref={ref}
      >
        <Box
          display="flex"
          justifyContent={bannerList.length <= 1 ? 'center' : 'start'}
          gap={1}
          sx={{
            overflowX: 'scroll',
            overflowY: 'hidden',
            width: '100%',
            height: bannerList.length ? `${Math.round(width * 92 / 276)}px` : '0',
          }}
        >
          {
            bannerList.map((banner, idx) => (
              <Box
                minWidth={`${width}px`}
                maxWidth={`${width}px`}
                height="100%"
                key={idx}
              >
                {renderBanner(banner)}
              </Box>
            ))
          }
        </Box>
      </Box>
    </Stack>
  )
}