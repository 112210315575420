import { Box, Button, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';

import AppBar from "@/components/AppBar";
import { useDispatch, useSelector } from '@/services/hooks';
import { useEffect, useMemo } from 'react';
import { getUserDetail } from '@/services/actions/userActions';
import { Plan, createPurchaseTemporaryId } from '@/services/models';
import _ from 'lodash';
import { clickPayment } from '@/utils';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { mainTheme } from '@/services/themes';
import SwapIcon from "@/assets/SwapIcon3.svg";
import ParallelSwitch from "@/assets/ParallelSwitch.svg";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FavoriteIcon from '@mui/icons-material/Favorite';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import TagManager from "react-gtm-module";
import CurrentPlan from '@/components/common/CurrentPlan';
import PlanFeatureDescription from '@/components/common/Plan Feature Description';


const FREE_PLAN_ID = 1;
const BRONZE_PLAN_ID = 2;

type propType = {
  plans: Plan[];
};

const FreePlan = (props: propType) => {
  const { plans } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const bronzePlan = useMemo(() => {
    return _.find(plans, { id: BRONZE_PLAN_ID });
  }, [plans]);

  useEffect(() => {
    dispatch(getUserDetail());
  }, [dispatch])

  const handlePlanChange = () => {
    if (bronzePlan?.id && user?.id) {
      TagManager.dataLayer({
        dataLayer: {
          event: "click_buy_plan_button",
          user_id: user.id,
        }
      });
      const temporaryId = createPurchaseTemporaryId();
      clickPayment({
        hash_id: user.hash_id,
        email: user.email,
        price: bronzePlan.price,
        recur_id: bronzePlan.recur_id,
        back_path: location.pathname,
        temporary_id: temporaryId,
      });
    }
  };

  const renderPlanItem = (props: {
    icon: JSX.Element,
    title: JSX.Element,
    description: JSX.Element,
  }) => {
    return (
      <Stack
        gap={2}
        display="flex"
        direction="row"
        alignItems="center"
      >
        {props.icon}
        <Typography typography="caption">{props.title}</Typography>
        <Typography typography="body2" fontWeight="bold">{props.description}</Typography>

      </Stack>
    )
  }

  const renderBuyBronzeButton = () => {
    if (!bronzePlan) {
      return null;
    }
    return (
      <Stack
        spacing={2}
        justifyContent="center"
        alignItems="center"
        display="flex"
        width="100%"
        paddingBottom={3}
      >
        <Typography variant="caption" sx={{ fontSize: '10px', color: 'rgba(0, 0, 0, 0.6)' }}>
          <FormattedMessage id="user.plan.free.button_caption" />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            marginTop: '8px !important',
          }}
          onClick={handlePlanChange}
        >
          {bronzePlan.name}<FormattedMessage id="user.plan.buy_plan" /> - ${bronzePlan.price.toLocaleString()}<FormattedMessage id="user.plan.per_month" />
        </Button>
      </Stack>

    );
  }

  return (
    <AppBar title={<FormattedMessage id="user.plan.title" />}>
      <div style={{ width: '100%', height: '100%', position: 'relative', background: 'white' }}>
        <List sx={{ padding: 0 }}>
          <CurrentPlan
            disableShowPoint
          />
          <ListItem
            sx={{
              display: 'flex',
              padding: '24px 16px 0',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '0',
              alignSelf: 'stretch',
            }}
          >
            <ListItemText
              disableTypography
              primary={<Typography variant="subtitle2" color="rgba(0, 0, 0, 0.87)"><FormattedMessage id="user.plan.plan_list" /></Typography>}
            />
            <List sx={{ padding: 0, width: '100%', marginBottom: '16px', }}>
              {plans.map(plan => {
                if (plan.id === FREE_PLAN_ID) {
                  return null;
                }
                return (
                  <ListItem
                    key={plan.id}
                    sx={{
                      display: 'flex',
                      padding: '16px 0',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      gap: '24px',
                      borderRadius: '4px',
                      border: `2px solid ${mainTheme.palette.primary.main}`,
                      marginTop: '16px',
                      fontFamily: 'Roboto',
                    }}
                  >
                    <Typography sx={{
                      padding: '0',
                      color: 'rgba(0, 0, 0, 0.87)',
                      fontSize: '24px',
                      fontWeight: 600,
                      fontFamily: 'Roboto',
                    }}>
                      {plan.name}
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 500,
                          display: 'inline-block',
                          marginLeft: '4px',
                          lineHeight: '133.4%',
                          fontFamily: 'Roboto',
                        }}
                      ><FormattedMessage id="user.plan.plan_suffix" /></Typography>
                    </Typography>
                    <Typography
                      sx={{
                        color: '#000',
                        fontSize: '20px',
                        fontWeight: 500,
                        lineHeight: '24px',
                        letterSpacing: '0.4px',
                        fontFamily: 'Roboto',
                        display: 'flex',
                      }}
                    >
                      ${plan.price.toLocaleString()}
                      <Typography variant="body1"><FormattedMessage id="user.plan.per_month" /></Typography>
                    </Typography>
                  </ListItem>
                )
              })}
            </List>

            {renderBuyBronzeButton()}
            <Stack
              spacing={2}
              width="100%"
              padding={2}
              margin="8px 0 16px"
              sx={{
                backgroundColor: '#F2F2F2',
              }}
            >
              <Typography variant="subtitle1">
                <FormattedMessage id="user.plan.free.bronze_can" />
              </Typography>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="13px"
              >
                <Stack
                  gap="10px"
                  width="50%"
                  padding="10px 0"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px',
                  }}
                >
                  <img style={{ width: '48px' }} src={SwapIcon} alt="swap icon" />
                  <Typography variant="subtitle2" color="textSecondary">
                    <FormattedMessage id="demo.switch_title" />
                  </Typography>
                  <Typography variant="subtitle2" fontSize="15px">
                    <FormattedMessage id="demo.plan.switch_description1" />
                    <br />
                    <FormattedMessage id="demo.plan.switch_description2" />
                  </Typography>
                </Stack>

                <Stack
                  width="50%"
                  gap="10px"
                  padding="10px 0"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px',
                  }}
                >
                  <FileUploadIcon color="primary" sx={{ fontSize: '48px' }} />
                  <Typography variant="subtitle2" color="textSecondary">
                    <FormattedMessage id="demo.max_character" />
                  </Typography>
                  <Typography variant="subtitle2" fontSize="15px">
                    <FormattedMessage id="demo.subscription_max_character_num" /><br />{"　"}
                  </Typography>
                </Stack>
              </Box>


              <Stack gap="4px" display="flex" justifyContent="center">
                {renderPlanItem({
                  icon: <FavoriteIcon color="primary" />,
                  title: <FormattedMessage id="demo.favorite_title" />,
                  description: <FormattedMessage id="demo.favorite_description" />,
                })}
                {renderPlanItem({
                  icon: <OndemandVideoIcon color="primary" />,
                  title: <FormattedMessage id="demo.av_title" />,
                  description: <FormattedMessage id="demo.av_description" />,
                })}
                {renderPlanItem({
                  icon: <img src={ParallelSwitch} alt="parallel swap icon" />,
                  title: <FormattedMessage id="demo.parallel_switch_title" />,
                  description: <FormattedMessage id="demo.parallel_switch_description" />,
                })}
              </Stack>

              <Stack
                spacing={2}
                width="100%"
              >
                <Typography
                  variant="subtitle1"
                  fontSize="18px"
                  textAlign="left"
                >
                  <FormattedMessage id="user.plan.free.point_title" />
                </Typography>

                <Stack
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="center"
                  gap="20px"
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={2}
                  >
                    <img style={{ width: '70px' }} src="/plan/Point1.png" srcSet="/plan/Point1@2x.png 2x,/plan/Point1@3x.png 3x" alt="point1" />
                    <Stack display="flex" justifyContent="center">
                      <Typography variant="subtitle1"><FormattedMessage id="user.plan.free.point1_text1" /></Typography>
                      <Typography variant="caption" color="textSecondary" sx={{ fontSize: '13px' }}><FormattedMessage id="user.plan.free.point1_text2" /></Typography>
                    </Stack>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={2}
                  >
                    <img style={{ width: '70px' }} src="/plan/Point2.png" srcSet="/plan/Point2@2x.png 2x,/plan/Point2@3x.png 3x" alt="point2" />
                    <Stack display="flex" justifyContent="center">
                      <Typography variant="subtitle1"><FormattedMessage id="user.plan.free.point2_text1" /></Typography>
                      <Typography variant="caption" color="textSecondary" sx={{ fontSize: '13px' }}><FormattedMessage id="user.plan.free.point2_text2" /></Typography>
                    </Stack>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={2}
                  >
                    <img style={{ width: '70px' }} src="/plan/Point3.png" srcSet="/plan/Point3@2x.png 2x,/plan/Point3@3x.png 3x" alt="point3" />
                    <Stack display="flex" justifyContent="center">
                      <Typography variant="subtitle1"><FormattedMessage id="user.plan.free.point3_text1" /></Typography>
                      <Typography variant="caption" color="textSecondary" sx={{ fontSize: '13px' }} > <FormattedMessage id="user.plan.free.point3_text2" /></Typography>
                    </Stack>
                  </Box>
                </Stack>
              </Stack>

              <Typography
                variant="subtitle1"
              ><FormattedMessage id="user.plan.free.announce" /></Typography>
              <Typography
                variant="caption"
                sx={{
                  fontSize: '9px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  whiteSpace: 'pre-line',
                  lineHeight: 1,
                }}
              >
                <FormattedMessage id="user.plan.free.announce_description" />
              </Typography>

            </Stack>
            {renderBuyBronzeButton()}

            <PlanFeatureDescription
              plans={plans}
            />

          </ListItem>
        </List >
      </div >
    </AppBar >
  );
};

export default FreePlan;
