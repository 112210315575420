import SceneComp from "@/components/Scene";
import { ApiService } from "@/services/api";
import { useSelector } from "@/services/hooks";
import { Scene } from "@/services/models";
import { TUTORIAL_STEP } from "@/services/tutorial";
import { Box, Button, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const LIMIT = 10;
export default function Favorite() {
  const navigateTo = useNavigate();
  const tutorialStep = useSelector((state) => state.common.tutorial_step);
  const isSwitchTutorial = tutorialStep === TUTORIAL_STEP.switch;


  const { user } = useSelector((state) => state.user);
  const [scenes, setScenes] = useState<Scene[]>([]);
  const [page, setPage] = useState(1);
  const [isLastScene, setIsLastScene] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const init = () => {
    setPage(1);
    setIsLastScene(false);
    setScenes([]);
    fetchScenes();
  }

  const fetchScenes = () => {
    if (isSwitchTutorial) {
      setScenes([]);
      return;
    }

    if (user?.is_free_plan) {
      setScenes([]);
      return;
    }

    const option = { page: 0 };

    ApiService.getFavoriteScenes(option)
      .then((data) => {
        if (data.length < LIMIT) {
          setIsLastScene(true);
        }
        setScenes(data);
      })
      .catch(() => setScenes([]));
  }

  const fetchNext = useCallback(() => {
    setLoadingMore(true);
    const option = { page };

    ApiService.getFavoriteScenes(option)
      .then((data) => {
        let isLastPage = false;
        if (data.length < LIMIT) {
          setIsLastScene(true);
          isLastPage = true;
        }
        TagManager.dataLayer({
          dataLayer: {
            event: "click_load_more",
            user_id: user?.id,
            page: page,
            is_last_page: isLastPage,
          }
        });
        setPage((before) => {
          return before + 1;
        });
        setScenes([...scenes, ...data]);
        setLoadingMore(false);
      });
  }, [scenes, page]);

  const onFavoriteChanged = (sceneId: number) => {
    setScenes(scenes.filter(scene => scene.id !== sceneId));
    return;
  }

  useEffect(() => {
    init();
  }, [tutorialStep, user]);

  return (
    <>
      <Stack direction="column" spacing={0} marginTop={0} paddingLeft={2} paddingRight={2} gap={3}>
        {scenes.map((scene, index) => (
          <Box key={`${scene.id}_${index}`}>
            <SceneComp
              isTutorial={isSwitchTutorial}
              index={index}
              scene={scene}
              showCharacter
              showDivider={index < scenes.length - 1}
              onFavoriteChanged={(id: number) => onFavoriteChanged(id)}
              sceneType="switched"
            />
          </Box>
        ))}

        {scenes.length > 0 && !isLastScene && (
          <Stack
            marginTop={2}
            width="100%"
          >
            <Button
              onClick={fetchNext}
              variant="contained"
              fullWidth
              disabled={loadingMore}
            >
              <FormattedMessage id="home.load_more" />
            </Button>
          </Stack>
        )}
      </Stack>

      {(!scenes.length && !user?.is_free_plan) && (
        <Box sx={{ width: '100%', textAlign: 'center' }} color="text.secondary" >
          <FormattedMessage id="home.empty_favorite" />
          <br />
          <FormattedMessage id="home.empty_favorite2" />
        </Box>
      )}

      {(!scenes.length && user?.is_free_plan) && (
        <Stack gap={2} display="flex" paddingX={2}>
          <Box sx={{ width: '100%', textAlign: 'center' }} color="text.secondary" >
            <FormattedMessage id="home.empty_favorite_free" />
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              navigateTo('/users/plan')
              TagManager.dataLayer({
                dataLayer: {
                  event: "move_plan_from_home_favorite",
                  user_id: user?.id,
                }
              });
            }}
          >
            <FormattedMessage id="video_stream.upgrade.button" />
          </Button>
        </Stack>
      )}
    </>
  );
}