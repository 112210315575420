import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import { useLocation } from 'react-router';

import { Container, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { mainTheme } from "@/services/themes";
import messages from '@/languageProvider';
import { getLocale } from '@/utils';
const locale = getLocale();

type RootLayoutProps = {
  children: React.ReactNode;
};

export const RootLayout: React.FC<RootLayoutProps> = ({ children }) => {
  const path = useLocation().pathname;
  useEffect(() => {
    // ページ遷移したらトップにスクロールし直す
    scrollTo(0, 0);
  }, [path]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>FACESWITCH</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Helmet>
        <CssBaseline />
        <IntlProvider locale={locale} messages={messages[locale]} >
          <ThemeProvider theme={mainTheme}>
            <Container maxWidth="sm" style={{ padding: 0 }}>
              {children}
            </Container>
          </ThemeProvider>
        </IntlProvider>
      </HelmetProvider>
    </>
  )
};
