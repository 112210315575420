/**
 * 新規マイキャラクター追加ページ
 * 下記のページから遷移できる
 * - 「+」アバターアイコンから
 * - キャラクター管理ページから
 */
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";

import {
  Button,
  Stack,
  TextField,
  Typography,
  CircularProgress, Box,
} from "@mui/material";
import { Upload } from "@mui/icons-material";
import AppBar from "@/components/AppBar";
import { ApiService } from "@/services/api";
import { mainTheme } from "@/services/themes";
import { openPlanDialog } from "@/services/actions/commonActions";
import { useDispatch, useSelector } from '@/services/hooks';
import axios from "axios";
import { FormattedMessage } from "react-intl";
import { ImageUploadModal } from "@/components/common/ImageUploadModal";
import { getUserDetail } from "@/services/actions/userActions";
import TagManager from "react-gtm-module";

export default function CharacterNew() {
  const dispatch = useDispatch();
  const { detail, user } = useSelector((state) => state.user);

  const fileRef = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<string>();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [name, setName] = useState<string>("");
  const [createdCharacterId, setCreatedCharacterId] = useState<number | null>();
  const [errorMessage, setErrorMessage] = useState<string | ReactElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isImageModalOpen, setImageModalOpen] = useState(false);

  const navigateTo = useNavigate();
  const MAX_SIZE = 5 * 1024 * 1024; // 5MB
  const IMAGE_MAX_HEIGHT = 640; // 5MB
  const ALLOWED_TYPES = ["image/jpeg", "image/png"];

  useEffect(() => {
    dispatch(getUserDetail())
  }, []);

  useEffect(() => {
    if (detail?.character?.plan_remain < 1) {
      dispatch(openPlanDialog('character'));
    }
  }, [detail]);

  const resizeImage = (imageElement: HTMLImageElement, file: File) => {
    const { naturalWidth, naturalHeight } = imageElement;
    const maxSize = IMAGE_MAX_HEIGHT; // maximum size in pixels
    if (naturalWidth > maxSize || naturalHeight > maxSize) {
      const aspectRatio = naturalWidth / naturalHeight;
      const newWidth = aspectRatio > 1 ? maxSize : Math.round(maxSize * aspectRatio);
      const newHeight = aspectRatio > 1 ? Math.round(maxSize / aspectRatio) : maxSize;
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(imageElement, 0, 0, newWidth, newHeight);
      const resizedUrl = canvas.toDataURL(file.type);
      imageElement.src = resizedUrl;
      setPreviewUrl(resizedUrl);
    } else {
      setPreviewUrl(imageElement.src);
    }
  };

  const validateImage = (image: File) => {
    if (!(image && image.name && typeof image.size === "number")) {
      return "invalid file object "
    }

    if (image.size > MAX_SIZE) {
      return "File is too large";
    }

    if (!ALLOWED_TYPES.includes(image.type)) {
      return "File type is not allowed";
    }

    return '';
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        const imageElement = new Image();
        imageElement.src = result;
        imageElement.onload = () => {
          resizeImage(imageElement, file);
        };
        setImage(result);
        TagManager.dataLayer({
          dataLayer: {
            event: "upload_character_image",
            user_id: user?.id,
          }
        });
        setImageModalOpen(true);
      };
      reader.readAsDataURL(file);
    } else {
      // image ではないとのエラーメッセージ？
    }
  };

  const handleSubmit = async () => {
    if (!image || !previewUrl) return;

    if (!name) {
      alert("名前を入力してください。");
      return;
    }

    const fileData = previewUrl.replace(/^data:\w+\/\w+;base64,/, '')
    const decodedFile = Buffer.from(fileData, 'base64')
    const file = new File([decodedFile], `${name}.png`, { type: 'image/png' })

    const error = validateImage(file);
    if (error) {
      alert(error);
      return;
    }

    try {
      setLoading(true);
      const data = await ApiService.createCharacter(name, file);

      if (data && data.id) {
        TagManager.dataLayer({
          dataLayer: {
            event: "generate_character",
            character_id: data.id,
            user_id: user?.id,
          }
        });
        setCreatedCharacterId(data.id);
      } else {
        console.log("failed to get character data");
        setErrorMessage(<FormattedMessage id="character.new.error_face_not_found" />);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 403) {
          dispatch(openPlanDialog('character'));
        }
      }
      console.error("Error uploading image:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleCancel = () => {
    navigateTo("/");
  };

  if (createdCharacterId) {
    navigateTo(`/`, { state: { createdCharacterId: createdCharacterId } });
  }

  return (
    <AppBar title={<FormattedMessage id="character.new.title" />}>
      <Stack spacing={2} margin={2} paddingTop={2}>
        <Typography variant="button" display="block" gutterBottom style={{ marginBottom: 8 }}>
          <FormattedMessage id="character.new.message1" />
        </Typography>

        <Stack
          alignItems="center"
          gap={2}
          direction="row"
        >
          <label
            htmlFor="upload-button"
            style={{
              maxWidth: '284px',
              width: "100%",
              display: image ? 'none' : 'inherit'
            }}
          >
            <Button
              variant="contained"
              component="span"
              startIcon={<Upload />}
              fullWidth
              sx={{
                backgroundColor: mainTheme.palette.primary.main,
                padding: '6px 12px',
                color: 'white',
                fontSize: '14px',
              }}
            >
              <span style={{ marginTop: 2 }}><FormattedMessage id="character.new.upload" /></span>
            </Button>
            <input
              ref={fileRef}
              id="upload-button"
              accept="image/*"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </label>

          <ImageUploadModal
            image={image}
            isImageModalOpen={isImageModalOpen}
            handleClose={() => setImageModalOpen(false)}
            setPreviewUrl={setPreviewUrl}
          />

          {previewUrl && (
            <div>
              <img
                src={previewUrl}
                alt="preview"
                style={{ width: "100%", marginTop: "20px", cursor: "pointer", display: 'inline-blcok' }}
                onClick={() => {
                  fileRef.current?.click();
                  setImageModalOpen(true);
                }}
              />
            </div>
          )}

          <TextField
            fullWidth
            required
            id="outlined-required"
            label={<FormattedMessage id="character.new.input_name" />}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Stack>

        <Box textAlign="center">
          <Button
            fullWidth
            variant="contained"
            disabled={!image || !name || loading}
            sx={{
              marginTop: '16px',
              padding: '8px 22px',
              maxWidth: '284px',
            }}
            onClick={handleSubmit}
          >
            {loading ? (
              <>
                <CircularProgress size={24} color="inherit" />
                <span style={{ marginLeft: 4 }}><FormattedMessage id="character.new.generating" /></span>
              </>
            ) : (
              <span><FormattedMessage id="character.new.create_character" /></span>
            )}
          </Button>
          <Typography
            variant="body2"
            sx={{ marginTop: '8px !important', marginBottom: '8px !important'}}
          >
            <FormattedMessage id="character.new.create_character_comment" />
          </Typography>
        </Box>
        <Box height={2}></Box>

        <Stack direction="column" gap={2} marginTop={2}>
          <img src="/chara_new_hint@2x.jpg" srcSet={"/chara_new_hint@2x.jpg 2x, /chara_new_hint@3x.jpg 3x"} alt="キャラクター作成ヒント" style={{width: "100%"}}/>
          <img src="/chara_new_hint2@2x.jpg" srcSet={"/chara_new_hint2@2x.jpg 2x, /chara_new_hint2@3x.jpg 3x"} alt="キャラクター作成ヒント2" style={{width: "100%"}}/>
        </Stack>

        <Button
          disabled={loading}
          variant="text"
          onClick={handleCancel}
        >
          <FormattedMessage id="character.new.cancel"/>
        </Button>
      </Stack>
      {
        errorMessage && (
          <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "white", padding: "20px", width: "343px", height: "fit-content", overflowY: "auto", boxShadow: "4px 4px 10px gray" }}>
            <Typography variant="h6" gutterBottom>
              <FormattedMessage id="character.new.error" />
            </Typography>
            <Typography variant="body1">
              {errorMessage}
            </Typography>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <Button variant="contained" onClick={() => setErrorMessage(null)}>
                <FormattedMessage id="character.new.ok" />
              </Button>
            </div>
          </div>
        )
      }
    </AppBar >
  );
}
