import { ReactElement, useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  Button,
  FormHelperText,
  FormControlLabel,
  Checkbox, MenuItem, Select, SelectChangeEvent,
} from "@mui/material";

import AppBar from "@/components/AppBar"
import { ApiService } from "@/services/api"
import { isAxiosError } from "axios";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useSelector } from "@/services/hooks";
import TagManager from "react-gtm-module";
import _ from "lodash";
import { initTutorialStep } from "@/services/tutorial";

export default function UserSignup() {
  const referralCode = useSelector(state => state.common.referral_code);
  const gentamaCode = useSelector(state => state.common.gentama_code);
  const monetrackCode = useSelector(state => state.common.monetrack_code);
  const { referrer } = useSelector(state => state.common);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [age, setAge] = useState<string>("");
  const [errors, setErrors] = useState<{ email: string | ReactElement; password: string | ReactElement }>({
    email: "",
    password: "",
  });
  const [serverError, setServerError] = useState<string | ReactElement>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | ReactElement>("");


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email: string): string | ReactElement => {
    const emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      return <FormattedMessage id="common.invalid.email_format" />
    }
    const localPart = email.split('@')[0];
    if (localPart.includes("+")) {
      return <FormattedMessage id="common.invalid.email_alias" />;
    }
    return "";
  };

  const validatePassword = (password: string): string | ReactElement => {
    if (_.size(password) < 8) {
      return <FormattedMessage id="common.password.invalid_length" />
    }
    return "";
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setErrors((prevErrors) => ({ ...prevErrors, email: validateEmail(value) }));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: validatePassword(value),
    }));
  };

  const handleAgeChange = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setAge(value);
  }

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      console.log("user registration star");

      const data = await ApiService.register({
        email,
        password,
        age,
        referral_code: referralCode,
        gentama_code: gentamaCode,
        monetrack_code: monetrackCode,
        terms_accepted: termsAccepted,
        referrer,
      });
      console.log("user registration: ", data);

      setServerError("");
      setSuccessMessage(<><FormattedMessage id="user.signup.success_message" /></>);

      TagManager.dataLayer({
        dataLayer: {
          event: "signup",
        }
      });
      initTutorialStep();
      // redirect("/");
      // show success message and to confirm token

      // serverError にエラー内容の書き込みが必要
      // そのため、エラーがあったときにここでメッセージ受け取れるように
    } catch (error: unknown) {
      console.log(error);
      if (isAxiosError(error)) {
        console.log("server error, error: ", error.response?.status);
        if (error?.response?.status === 401) {
          setServerError(<FormattedMessage id="user.signup.already_signup" />);
        } else {
          setServerError(error.message);
        }
      }
      setIsSubmitting(false);
    }
  };

  const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked);
  };

  const isFormValid =
    !errors.email && !errors.password && email && password && age && termsAccepted;

  return (
    <AppBar title={<FormattedMessage id="user.signup.title" />}>
      <Stack spacing={2} margin={2} paddingTop={2}>
        <img
          src="/switch_trial_banner2.png"
          alt="banner"
        />
        <Typography variant="body1" display="block" gutterBottom>
          <FormattedMessage id="user.signup.description" />
        </Typography>

        <FormControl variant="outlined" fullWidth>
          <TextField
            required
            label={<FormattedMessage id="user.signup.login_id" />}
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            helperText={errors.email}
            autoComplete="email"
            error={!!errors.email}
          />
        </FormControl>

        <FormControl variant="outlined" fullWidth error={!!errors.password}>
          <InputLabel htmlFor="outlined-adornment-password">
            <FormattedMessage id="user.signup.password" />
          </InputLabel>{" "}
          <OutlinedInput
            id="outlined-adornment-password"
            label={<FormattedMessage id="user.signup.password" />}
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            autoComplete="password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {errors.password && <FormHelperText>{errors.password}</FormHelperText>}
        </FormControl>

        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-age-select">
            <FormattedMessage id="user.signup.age" />
          </InputLabel>
          <Select
            value={age}
            onChange={handleAgeChange}
            label="age"
            inputProps={{
              name: 'age',
              id: 'outlined-age-select',
            }}
          >
            <MenuItem value="18~19歳"><FormattedMessage id="user.signup.age_10" /></MenuItem>
            <MenuItem value="20~29歳"><FormattedMessage id="user.signup.age_20" /></MenuItem>
            <MenuItem value="30~39歳"><FormattedMessage id="user.signup.age_30" /></MenuItem>
            <MenuItem value="40~49歳"><FormattedMessage id="user.signup.age_40" /></MenuItem>
            <MenuItem value="50~59歳"><FormattedMessage id="user.signup.age_50" /></MenuItem>
            <MenuItem value="60~69歳"><FormattedMessage id="user.signup.age_60" /></MenuItem>
            <MenuItem value="70~79歳"><FormattedMessage id="user.signup.age_70" /></MenuItem>
            <MenuItem value="80歳~"><FormattedMessage id="user.signup.age_80" /></MenuItem>
            <MenuItem value="未回答"><FormattedMessage id="user.signup.age_no" /></MenuItem>
          </Select>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={handleTermsChange}
              name="termsAccepted"
              color="primary"
            />
          }
          label={<Typography variant="body1">
            <Link
              target="_blank"
              to="/policy"
              style={{
                textDecoration: 'none',
                color: '#F06292',
              }}
            >
              <FormattedMessage id="user.signup.policy" />
            </Link>
            <FormattedMessage id="user.signup.sep" />
            <Link
              target="_blank"
              to="/terms"
              style={{
                textDecoration: 'none',
                color: '#F06292',
              }}
            >
              <FormattedMessage id="user.signup.terms" />
            </Link>
            <FormattedMessage id="user.signup.policy_agree" />
          </Typography>}
        />

        {serverError && <p style={{ color: "#d32f2f" }}>{serverError}</p>}
        {successMessage && <p style={{ color: "#333" }}>{successMessage}</p>}
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!isFormValid || isSubmitting}
        >
          <FormattedMessage id="user.signup.register" />
        </Button>
      </Stack>
    </AppBar>
  );
}
