// Terms.tsx
import React from "react";

import { Typography, Stack } from "@mui/material";

import AppBar from "@/components/AppBar";
import { FormattedMessage } from "react-intl";
// import { getLocale } from "@/utils";

const Law: React.FC = () => {
  const tdStyle = {
    border: '1px solid rgb(160 160 160)',
    padding: '8px 10px',
  }

  return (
    <AppBar title={<FormattedMessage id="law.title" />}>
      <Stack padding={2}>
        <Typography variant="body1">
          <table
            style={{
              width: '100%',
              borderCollapse: 'collapse',
              border: '2px solid rgb(140 140 140)',
            }}
          >
            <tr>
              <td style={{ width: '35%', ...tdStyle }}>事業者の名称</td>
              <td style={tdStyle}>AI Pioneer Limited</td>
            </tr>
            <tr>
              <td style={tdStyle}>事業者の所在地</td>
              <td style={tdStyle}>Flat 1, 5/F, Cammer Commercial Building, 30-32 Cameron Road, Tsim Sha Tsui, Kowloon,
                Hong Kong</td>
            </tr>
            <tr>
              <td style={tdStyle}>事業者の責任者</td>
              <td style={tdStyle}>Bernard Paule CASIMIRO</td>
            </tr>
            <tr>
              <td style={tdStyle}>連絡先</td>
              <td style={tdStyle}>
                TEL：（+852）8198-1703(※)<br />
                E-mail：company@ai-pioneers.asia<br />
                ※電話でのお問い合わせは受け付けておりません。お問い合わせは下記のアドレスまでお願いいたします。<br />
                お問い合わせ先：contact@face-switch-ai.com
              </td>
            </tr>
            <tr>
              <td style={tdStyle}>サービス価格</td>
              <td style={tdStyle}>サービスページに表示された各プランの価格に基づきます。</td>
            </tr>
            <tr>
              <td style={tdStyle}>サービス価格以外の必要料金</td>
              <td style={tdStyle}>ウェブページの閲覧、サービスの利用等に必要となるインターネット接続料金、通信料金等
                は、お客様のご負担となります。それぞれの料金は、お客様がご利用のインターネットプロバ
                イダ、携帯電話会社等にお問い合わせください。
                <br />
                その他必要となる料金は以下の通りです。
              </td>
            </tr>
            <tr>
              <td style={tdStyle}>サービスの申込み期間</td>
              <td style={tdStyle}>お申込み期間の設定がある場合には、その旨及びその内容について、各商品ご購入の最
                終確認画面にて表示します。</td>
            </tr>
            <tr>
              <td style={tdStyle}>支払方法</td>
              <td style={tdStyle}>クレジットカード決済
                （お支払いはすべて米国ドル（USD）で請求されます。為替レートの変動により、請求額が異
                なる場合があります。為替レートの変動に関しての詳細は、お使いのクレジットカード会社に
                お問い合わせいただくか、ご利用明細をご確認ください。）</td>
            </tr>
            <tr>
              <td style={tdStyle}>支払時期</td>
              <td style={tdStyle}>クレジットカード会社が定める引き落とし日に支払いとなります。</td>
            </tr>
            <tr>
              <td style={tdStyle}>サービスの提供時期</td>
              <td style={tdStyle}>クレジットカード決済が正常に確認された後、即時に開始されます。</td>
            </tr>
            <tr>
              <td style={tdStyle}>解約、キャンセル、変更</td>
              <td style={tdStyle}>契約(利用)後のキャンセルには対応しかねます。
                変更に関する詳細は、サービス利用規約をご確認ください。</td>
            </tr>
            <tr>
              <td style={tdStyle}>動作環境</td>
              <td style={tdStyle}>
                ●PC<br />
                ・推奨 OS<br />
                Windows 11 以降<br />
                MacOSX v13 以降<br />
                ・推奨ブラウザ<br />
                Edge（最新版）<br />
                Chrome（最新版）<br />
                Firefox（最新版）<br />
                Safari（最新版）<br />
                ●スマートフォン・タブレット<br />
                ・推奨 OS<br />
                iOS 16 以降<br />
                Android OS13 以降<br />
                ・推奨ブラウザ<br />
                iOS：Safari （最新版）<br />
                Android：Chrome （最新版）
              </td>
            </tr>
          </table>
        </Typography>
      </Stack>
    </AppBar >
  );
};

export default Law;
